.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    max-width: 188px;
    height: auto;
}

.pagination {
    height: 8px;
    display: flex;
    justify-content: space-between;
    margin: 80px auto 0;
    padding: 0 20px;
    width: 200px;
}

.swiper-pagination {
    height: 8px;
    display: flex;
    justify-content: space-between;
    margin: 80px auto 0;
    width: 100%;
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 7px;
    background: #484848;
}

.swiper-pagination-bullet:active {
    background: #f4c76f;
}

.swiper-pagination-bullet-active {
    width: 8px;
    height: 8px;
    margin: 0 7px;
    border-radius: 50%;
    background: #f4c76f;
}
