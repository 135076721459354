.card-item {
    background-color: var(--BACKGROUND_PAPER);
    border-radius: 24px;
    padding-left: 38px;
    height: 250px;
    min-width: 300px;
    max-width: 362px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: start;
    transition: 0.3s ease-in-out;
    z-index: 4;
    fill: rgba(17, 25, 53, 0.5);
}

.card-item:hover {
    cursor: pointer;
    fill: #0a66c2;
    fill-opacity: 1;
}

.card-item-mob {
    margin: 0 auto;
    background-color: var(--BACKGROUND_PAPER);
    border-radius: 24px;
    padding-left: 28px;
    min-width: 260px;
    min-height: 189px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    text-align: start;
    transition: 0.3s ease-in-out;
    z-index: 4;
    fill: rgba(17, 25, 53, 0.5);
}

.card-item-mob:hover {
    cursor: pointer;
    fill: #0a66c2;
    fill-opacity: 1;
}

.card-texts {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 36px;
}

.card-texts-mob {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 27px;
    height: calc(100% - 24px);
}

.card-title {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    margin: 30px 0 21px;
    line-height: 25px;
}

.card-subtitle {
    margin-top: 0;
    margin-bottom: 40px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(54, 62, 87, 0.7);
}

.card-pic {
    width: 185px;
    height: 235px;
    border-radius: 0 0 24px 0;
}

.card-title-mob {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    margin: 25px 0 10px;
}

.card-subtitle-mob {
    margin-top: -12px;
    margin-bottom: 16px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    white-space: nowrap;
    color: rgba(54, 62, 87, 0.7);
}

.card-pic-mob {
    width: 134px;
    height: 172px;
    border-radius: 0 0 24px 0;
}
