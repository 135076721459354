.select-style {
    height: 19px;
    width: 44px;
    padding: 0;
    border: 0;
    outline: 0;
    z-index: 10;
    color: var(--WHITE);
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent
        url("data:image/svg+xml;utf8,<svg width='6' height='3' viewBox='0 0 6 3' fill='none' xmlns='http://www.w3.org/2000/svg'> <path d='M3 3L0.401924 -4.89399e-07L5.59808 -3.51373e-08L3 3Z' fill='white'/> </svg> ")
        no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
}
