section {
    opacity: 0;
    transform: translate(-20%, 0);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out, visibility 1200ms ease-out;
    will-change: opacity, visibility;
}

.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
    display: flex;
    flex-direction: column;
    z-index: 5;
    position: relative;
}
