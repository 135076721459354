.title {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 75px;
    color: var(--WHITE);
    z-index: 5;
    position: relative;
    max-width: 700px;
    margin: 46px 0;
}

.title-mobile {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
    color: var(--WHITE);
    z-index: 5;
    position: relative;
}
