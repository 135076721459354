.primary-button {
    background-color: var(--WHITE);
    border: none;
    width: 194px;
    height: 60px;
    border-radius: 20px;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0432c1;
    z-index: 10;
}

.primary-button-dense {
    width: 194px;
    height: 60px;
    background-color: var(--WHITE);
    border: none;
    border-radius: 20px;
    font-style: normal;
    text-align: center;
    font-family: 'Lexend', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0432c1;
    z-index: 10;
}
