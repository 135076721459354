.about-div {
    position: relative;
    background-color: var(--PRIMARY_MAIN);
    padding: 0 0 0 48px;
}

.about-div-mobile {
    position: relative;
    background-color: var(--WHITE);
    padding-bottom: 50px;
}

.about-div-mobile-color {
    background-color: var(--PRIMARY_MAIN);
}

.about-div-mrgn {
    position: relative;
    /*background-color: var(--WHITE);*/
    width: 100%;
    /*margin: 0 auto -80px;*/
    /*height: 80px;*/
    /*margin-left: -48px;*/
}

.about-layout-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    text-align: start;
    padding: 0 24px;
    margin-bottom: 30px;
}

.about-layout-desktop {
    display: flex;
    max-width: calc(var(--MAX_WH_DESK) + 48px);
    width: 100%;
    margin: 0 auto;
}

.about-text-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    /*max-width: var(--MAX_WH_DESK);*/
    margin: 0 0 134px;
    text-align: start;
}

.about-text-title {
    width: var(--MAX_WH_DESK);
}

.about-text-title-svg {
    position: absolute;
    top: 110px;
    left: 22%;
}

.about-icon {
    height: 702px;
}

.about-text {
    margin-bottom: 62px;
    margin-right: 20px;
    z-index: 2;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: var(--WHITE);
    max-width: 556px;
}

.about-text-mobile {
    margin-bottom: 62px;
    z-index: 2;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 30px;
    color: var(--WHITE);
}

.about-pic {
    position: absolute;
    bottom: -69px;
    right: -76px;
    z-index: 1;
}

@media only screen and (max-width: 1250px) {
    .about-pic {
        right: -25%;
    }
}

@media only screen and (max-width: 1150px) {
    .about-pic {
        right: -30%;
    }

    .about-text-title {
        width: auto;
    }

    .about-text-title-svg {
        display: none;
    }
}

@media only screen and (max-width: 1100px) {
    .about-pic {
        right: -50%;
    }
}

@media only screen and (max-width: 1100px) {
    .about-pic {
        right: -65%;
    }
}

@media only screen and (max-width: 900px) {
    .about-pic {
        right: -75%;
    }
}

.about-pic-mob-div {
    position: relative;
}

.about-pic-mob {
    width: 100%;
    height: 100%;
    margin-bottom: -14.7%;
}

@media only screen and (max-width: 560px) {
    .about-pic-mob {
        margin-bottom: -14.75%;
    }
}

@media only screen and (max-width: 422px) {
    .about-pic-mob {
        margin-bottom: -14.8%;
    }
}

@media only screen and (max-width: 422px) {
    .about-pic-mob {
        margin-bottom: -14.9%;
    }
}

.services-layout {
    margin-top: 94px;
    position: relative;
}

.services-layout-dense {
    padding: 0;
}

.services-items-bg {
    height: 500px;
    position: absolute;
    width: 100vw;
    left: 0;
    margin-top: 5px;
    background-color: var(--PRIMARY_MAIN);
}

.services-items {
    padding-top: 90px;
    z-index: 2;
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, 262px);
    grid-auto-rows: 1fr;
    grid-row-gap: 27px;
    grid-column-gap: 27px;
    justify-content: center;
    max-width: var(--MAX_WH_DESK);
    margin: 0 auto;
}

.services-item {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: var(--WHITE);
    border: 1px solid #2576ef;
    border-radius: 24px;
    max-width: 262px;
    min-height: 228px;
    margin-bottom: 27px;
    padding: 16px;
}

.services-item > svg {
    margin: 39px auto 28px;
}

.services-item-mobile {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--WHITE);
    border: 1px solid #2576ef;
    border-radius: 15px;
    max-width: 128px;
    min-height: 180px;
    margin-bottom: 27px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    letter-spacing: -0.02em;
    padding: 16px;
    color: #111935;
}

.services-item-mobile > svg {
    margin: 9px auto;
    width: 38px;
    height: 38px;
}

.services-buttons {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 27px;
    grid-column-gap: 16px;
    align-items: center;
    justify-content: center;
}

.services-button-l-mobile {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: none;
    background: #2576ef;
}

.services-button-r-mobile {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border: none;
    background: #2576ef;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.services-button-l-mobile:disabled {
    background: rgba(17, 25, 53, 0.15);
}

.services-button-r-mobile:disabled {
    background: rgba(17, 25, 53, 0.15);
}

.text-align-start {
    max-width: var(--MAX_WH_DESK);
    text-align: start;
    margin: 0 auto;
    z-index: 5;
    padding: 0 48px;
}

.text-align-start-dense {
    text-align: start;
    z-index: 5;
    padding: 0 24px;
}

.team-layout {
    margin-top: 192px;
    position: relative;
}

.team-layout-dense {
    margin-top: 80px;
    position: relative;
    padding: 0;
}

.team-items {
    width: calc(100% - 48px - 48px);
    max-width: var(--MAX_WH_DESK);
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, 362px);
    grid-row-gap: 30px;
    padding: 0 48px;
    justify-content: center;
    grid-column-gap: 30px;
}

.team-items-mob {
    width: calc(100% - 24px - 24px);
    max-width: var(--MAX_WH_DESK);
    margin: 0 auto;
    display: grid;
    grid-row-gap: 30px;
    grid-auto-rows: 1fr;
    /*padding: 0 24px;*/
    justify-content: center;
}

.experience-layout {
    margin-top: 204px;
    position: relative;
    width: 100%;
}

.experience-pic {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom center;
    background-image: image-set(
        url('../../assets/images/experience/exp-1x.webp') 1x,
        url('../../assets/images/experience/exp-2x.webp') 2x,
        url('../../assets/images/experience/exp-1x.png') 1x,
        url('../../assets/images/experience/exp-2x.png') 2x
    );
}

.experience-layout-dense {
    margin-top: 130px;
    margin-bottom: 40px;
    position: relative;
    padding: 0 24px;
}

.experience-items {
    max-width: var(--MAX_WH_DESK);
    margin: 0 auto;
    position: relative;
    width: 100%;
}

.experience-item {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: var(--TEXT_PRIMARY);
    margin: 50px 0;
}
.experience-item-bold-1 {
    display: grid;
    grid-template-columns: auto auto;
    max-width: 530px;
    margin-left: 40%;
    /*position: absolute;*/
    /*left: 40%;*/
}

.experience-item-bold-1 > b {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 146px;
    margin-right: 43px;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_MAIN);
}

.experience-item-bold-1 > p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #363e57;
    margin-top: 24px;
    text-align: start;
}

.experience-item-bold-2 {
    display: grid;
    grid-template-columns: auto auto;
    max-width: 600px;
    /*position: absolute;*/
    /*left: 0;*/
}

.experience-item-bold-2 > b {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 165px;
    line-height: 206px;
    margin-right: 34px;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_MAIN);
}

.experience-item-bold-2 > p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #363e57;
    margin-top: 42px;
    text-align: start;
}

.experience-item-bold-3 {
    display: grid;
    grid-template-columns: auto auto;
    max-width: 611px;
    margin-left: 45%;
}

.experience-item-bold-3 > b {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 165px;
    line-height: 206px;
    letter-spacing: -0.02em;
    margin-right: 36px;
    color: var(--PRIMARY_MAIN);
}

.experience-item-bold-3 > p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #363e57;
    margin-top: 40px;
    text-align: start;
}

.experience-item-bold-4 {
    display: grid;
    grid-template-columns: auto auto;
    max-width: 600px;
    margin-left: 10%;
}

.experience-item-bold-4 > b {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 150px;
    margin-right: 16px;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_MAIN);
}

.experience-item-bold-4 > p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #363e57;
    margin-top: 24px;
    text-align: start;
}

.experience-item-bold-5 {
    display: grid;
    grid-template-columns: auto auto;
    max-width: 530px;
    margin-left: 45%;
    margin-bottom: 163px;
}

.experience-item-bold-5 > b {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 120px;
    line-height: 150px;
    letter-spacing: -0.02em;
    margin-right: 36px;
    color: var(--PRIMARY_MAIN);
}

.experience-item-bold-5 > p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    color: #363e57;
    margin-top: 24px;
    text-align: start;
}

.experience-item-bold {
    display: grid;
    grid-template-columns: 130px auto;
    text-align: start;
    max-width: 400px;
    margin: 0 auto;
    align-items: flex-start;
}

.experience-item-bold > b {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 85px;
    line-height: 104px;
    letter-spacing: -0.02em;
    color: var(--PRIMARY_MAIN);
    margin: 0;
}

.experience-item-bold > p {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #363e57;
    margin-top: 18px;
    text-align: start;
    font-size: 13px;
    line-height: 20px;
}

.industries-layout {
    position: relative;
    margin: 0;
    overflow: hidden;
    background-color: var(--PRIMARY_MAIN);
}

.industries-layout-dense {
    padding: 24px 0 105px;
    background-color: var(--PRIMARY_MAIN);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.industries-items {
    display: grid;
    z-index: 2;
    width: 100%;
    position: relative;
    grid-template-columns: auto auto;
    grid-row-gap: 86px;
    grid-column-gap: 111px;
    justify-content: center;
    margin: 0 auto;
    color: var(--WHITE);
}

.industries-items-dense {
    display: grid;
    grid-template-columns: auto auto;
    grid-row-gap: 79px;
    grid-column-gap: 79px;
    padding: 0 24px;
}

.industries-item-dense {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 50px 20px 0;
}

.industries-pic-mob {
    width: 100%;
    height: auto;
    position: relative;
}

@media only screen and (max-width: 500px) {
    .industries-pic-mob {
        width: 100vw;
    }
}

.dot-container {
    display: flex;
    justify-content: space-between;
    width: 80px;
    margin: 46px auto 155px;
}

.dot {
    background: rgba(110, 115, 121, 0.3);
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.industries-text-align-start {
    display: flex;
    max-width: var(--MAX_WH_DESK);
    text-align: start;
    margin: 0 auto;
    padding: 230px 48px 90px;
    z-index: 5;
}

.industries-item-desk {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
}

.industries-item-desk > svg {
    margin-bottom: 70px;
}

.industries-item-desk > p {
    margin: 0;
}

.industries-item-desk1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
}

.industries-item-desk1 > svg {
    margin-bottom: 46px;
}

.industries-item-desk1 > p {
    margin: 0;
}

.ind-pic-div {
    position: absolute;
    top: 360px;
    left: 15%;
    height: 750px;
}

.ind-pic {
    height: 750px;
    margin-left: 0;
}

@media only screen and (max-width: 1920px) {
    .ind-pic-div {
        left: 0;
    }
}

@media only screen and (max-width: 800px) {
    .ind-pic-div {
        left: -25%;
    }
}

@media only screen and (max-width: 1200px) {
    .ind-pic-div {
        left: -15%;
    }
}

.ind-bottom {
    height: 380px;
    background-color: var(--WHITE);
}

.industries-item-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--WHITE);
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
}

.industries-item-mob > svg {
    margin-bottom: 50px;
}

.industries-item-mob-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--WHITE);
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
}

.industries-item-mob-1 > svg {
    margin-bottom: 30px;
}
