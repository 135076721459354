.t-input-style {
    background: var(--WHITE);
    border: 1px solid #e8ebf3;
    width: 100%;
    border-radius: 6px;
    padding: 0 14px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--TEXT_HINT);
    height: 58px;
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}

.t-placeholder-style {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(17, 25, 53, 0.7);
    opacity: 0.5;
    margin-bottom: 15px;
    text-align: start;
}
