.contact-us-block {
    display: grid;
    width: 100%;
}

.contact-us-layout {
    display: flex;
}

.textarea-style {
    background: rgba(17, 25, 53, 0.02);
    border: 1px solid rgba(54, 62, 87, 0.2);
    border-radius: 6px;
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--TEXT_HINT);
    height: 124px;
    min-width: 120px;
    width: 100%;
}

.textarea-style-modal {
    background: transparent;
    border: 1px solid rgba(54, 62, 87, 0.2);
    border-radius: 6px;
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--TEXT_HINT);
    height: 124px;
    min-width: 120px;
    width: 100%;
}

.textarea-style-mob {
    background: rgba(17, 25, 53, 0.02);
    border: 1px solid rgba(54, 62, 87, 0.2);
    border-radius: 6px;
    padding: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--TEXT_HINT);
    height: 100px;
    width: 100%;
    min-width: 120px;
}
