.footer {
    background-color: var(--WHITE);
    width: 100%;
    margin-top: 0;
    margin-bottom: 160px;
}

.footer-layout {
    display: flex;
    max-width: var(--MAX_WH_DESK);
    margin: 0 auto;
    align-items: center;
    padding: 0 48px;
}

.footer-layout-logo {
    width: 68px;
    height: 66px;
    margin-right: 43px;
}

.footer-flex {
    display: grid;
    grid-template-columns: 3fr 4fr;
    justify-content: flex-start;
}

.footer-address-block {
    display: block;
    max-width: 400px;
    text-align: start;
    margin-top: 0;
}

.footer-address-text {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: var(--PRIMARY_MAIN);
}

.footer-address-text > b {
    font-weight: 700;
    white-space: nowrap;
}

.footer-contact-us-block {
    display: block;
    width: 100%;
}

.footer-contact-us-text {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 51px;
    color: var(--PRIMARY_MAIN);
    white-space: nowrap;
    text-align: start;
    margin-top: 8px;
    position: relative;
}

.footer-contact-us-text #v1 {
    position: absolute;
    top: 60px;
    left: 105px;
}

.footer-contact-us-text #v9 {
    position: absolute;
    top: 180px;
    left: 185px;
}
