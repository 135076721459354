.footer-dense {
    background-color: var(--WHITE);
    padding: 0 24px;
    margin-top: 0;
    display: flex;
    flex-direction: column;
    position: relative;
}

.footer-icon-div {
    margin: 79px 0 62px auto;
}

.footer-contact-us-text-dense {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: var(--PRIMARY_MAIN);
    text-align: start;
    margin-bottom: 108px;
}

.footer-address-block {
    display: block;
    max-width: 400px;
    text-align: start;
    margin-top: 16px;
}

.footer-address-block-mobile {
    opacity: 0.5;
    margin-bottom: 65px;
}

.footer-address-text-mobile {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    margin: 0 auto 20px;
    width: calc(100% - 36px);
    color: var(--PRIMARY_MAIN);
}

.footer-contact-us-block {
    display: block;
}

.footer-contact-us-text {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    color: var(--BACKGROUND_PAPER);
    margin-right: 46px;
    white-space: nowrap;
}
