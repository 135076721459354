@keyframes zoomIn {
    0% {
        scale: 0;
        background-color: var(--PRIMARY_MAIN);
    }
    100% {
        scale: 1;
        background-color: var(--PRIMARY_MAIN);
    }
}

.mobile-menu-layout {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: var(--PRIMARY_MAIN);
    z-index: 10;
    animation: 0.3s cubic-bezier(0.465, 0.183, 0.153, 0.946) zoomIn;
    transform-origin: top center;
}

.mobile-menu-logo {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 40px;
    height: 40px;
}

.close-button-mobile {
    position: absolute;
    top: 40px;
    right: 40px;
    border: none;
    background-color: transparent;
    width: 36px;
    cursor: pointer;
}

.mobile-menu-get-btn {
    width: 194px;
    height: 60px;
    background: var(--WHITE);
    border-radius: 20px;
    border: none;
    margin: 69px auto 0;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0432c1;
}
