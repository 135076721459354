.header {
    padding: 48px;
    background-color: var(--PRIMARY_MAIN);
}

.header-layout {
    max-width: var(--MAX_WH_DESK);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.header-layout-menu {
    display: flex;
    align-items: center;
}

.header-layout-menu > select {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    border: none;
    color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: transparent
        url("data:image/svg+xml;utf8,<svg fill='white' width='24' height='3' viewBox='0 0 24 3' xmlns='http://www.w3.org/2000/svg'><path d='M3 3L0.401924 -4.89399e-07L5.59808 -3.51373e-08L3 3Z'/></svg>")
        no-repeat;
    background-position-x: 100%;
    background-position-y: 7px;
    margin-right: 24px;
    padding-right: 24px;
}

.header-layout-menu > svg {
    width: 68px;
    height: 68px;
}

.header-dense {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 24px;
    background-color: var(--PRIMARY_MAIN);
}

.menuLayout {
    display: flex;
    width: 100%;
    max-width: 500px;
    white-space: nowrap;
    color: var(--BACKGROUND_PAPER);
    padding: 0 51px;
}

.menuLayout > p {
    padding-right: 58px;
}

@media only screen and (max-width: 900px) {
    .menuLayout > p {
        padding-right: 28px;
    }
}

.menuLayout2 {
    display: flex;
    max-width: var(--MAX_WH_DESK);
    white-space: nowrap;
    color: var(--BACKGROUND_PAPER);
    justify-content: flex-end;
    margin: 80px auto 0;
    align-items: center;
    padding: 0 24px;
}

.menuLayout2 > p {
    padding-left: 58px;
}

@media only screen and (max-width: 900px) {
    .menuLayout2 > p {
        padding-left: 28px;
    }
}

.menuLayoutDense {
    display: block;
    width: 100%;
    white-space: nowrap;
    color: var(--BACKGROUND_PAPER);
    max-width: 100%;
    margin-top: 90px;
}

.menuText {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    cursor: pointer;
}

.menuText:hover {
    cursor: pointer;
    text-decoration: underline;
}

.menuText2 {
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    color: rgba(17, 25, 53, 0.7);
}

.menuText2:hover {
    cursor: pointer;
    text-decoration: underline;
}

.mobile-menu {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.menuTextMobile {
    margin: 33px auto 0;
    font-family: 'Lexend', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #ffffff;
    width: fit-content;
    text-align: center;
}
