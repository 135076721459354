svg {
    overflow: hidden;
}

img {
    -webkit-user-drag: none;
}

html,
body {
    width: 100%;
    min-width: 320px;
    scroll-behavior: smooth;
    overflow: hidden auto;
}

body {
    margin: 0;
    font-family: 'Open Sans', 'Lexend', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: 'Open Sans', 'Lexend', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
