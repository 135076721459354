@import './assets/fonts/Open-Sans/style.css';
@import './assets/fonts/Lexend/style.css';

.App {
    text-align: center;
    background-color: var(--WHITE);
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    min-width: 320px;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

:root {
    --PRIMARY_MAIN: #2576ef;
    --SECONDARY_MAIN: #1cdada;
    --WHITE: #ffffff;
    --BACKGROUND_PAPER: #f6f7f8;
    --TEXT_HINT: #6e7379;
    --TEXT_PRIMARY: #000000;
    --TEXT_HEADER: #111935;
    --TEXT_MENU: #15161b;
    --MAX_WH_DESK: 1146px;
}
